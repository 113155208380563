<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="customers"
      :fields="fields"
      responsive
    >
      <!-- Nombre -->
      <template #cell(name)="data">
        <router-link
          :to="{ name: 'projects', params: { customerId: data.item.id } }"
          class="text-primary font-weight-bolder"
        >
          {{ data.value }}
        </router-link>
      </template>

      <!-- Email -->
      <template #cell(email)="data">
        <div>
          {{ data.value }}
        </div>
      </template>

      <!-- Fecha de creación -->
      <template #cell(created_at)="data">
        <div>{{ data.value | date }}</div>
      </template>

      <!-- Estado de openQuotation -->
      <template #cell(openQuotation)="data">
        <div class="text-align-center w-100">
          <label class="switch">
            <input
              :checked="data.value"
              type="checkbox"
              @change="(event) => handleSwitchToggle(event, data.item)"
            />
            <span
              id="switchSlider"
              :class="[data.value ? '' : 'closed', 'slider round']"
            />
          </label>
        </div>
      </template>

      <!-- Acciones -->
      <template #cell(tools)="data">
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <!-- <b-dropdown-item @click="deactivateCustomer(data.item.id)">{{
            $t('deactivate')
          }}</b-dropdown-item> -->
          <b-dropdown-item
            v-b-modal.confirmDelete
            variant="danger"
            @click="customerToDeleteId = data.item.id"
          >{{ $t('delete') }}</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- Modal de confirmación -->
    <confirmation-modal
      :id="`confirmDelete`"
      :variant="'danger'"
      @confirm="removeCustomer"
    >
      <template v-slot:content>
        {{ $t('deleteCustomerConfirmation') }}
      </template>
    </confirmation-modal>

    <!-- Paginación -->
    <b-pagination
      class="mt-1 ml-1"
      :value="pagination.page"
      :total-rows="pagination.total_objects"
      :per-page="pagination.per_page"
      @change="handlePageChange"
    />
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: { ConfirmationModal },
  props: {
    customers: {
      type: Array,
      default() {
        return []
      },
    },
    pagination: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: this.$t('name') },
        { key: 'email', label: this.$t('email') },
        { key: 'phone', label: this.$t('phone') },
        { key: 'created_at', label: this.$t('createdAt') },
        { key: 'openQuotation', label: this.$t('openQuotation') },
        { key: 'tools', label: this.$t('actions') },
      ],
      customerToDeleteId: null,
    }
  },
  methods: {
    ...mapMutations('customers', {
      changePage: 'CHANGE_PAGE',
    }),
    ...mapActions('customers', ['deleteCustomer', 'fetchCustomers', 'updateOpenQuotation']),
    async removeCustomer() {
      await this.deleteCustomer(this.customerToDeleteId)
      await this.fetchCustomers({})
    },
    handlePageChange(page) {
      this.loading = true
      this.changePage(page)
      this.fetchCustomers({ pagination: this.pagination }).then(() => {
        this.loading = false
      })
    },
    async handleSwitchToggle(event, customer) {
      const newStatus = event.target.checked
      try {
        // Llamar a la acción de Vuex para actualizar el estado
        await this.$store.dispatch('customers/updateOpenQuotation', {
          customerId: customer.id,
          openQuotation: newStatus,
        })

        // Mostrar notificación de éxito
        this.$toast.success(
          `Se actualizo las cotizaciones a ${
            newStatus ? '"ABIERTA"' : '"CERRADA"'
          }.`,
        )

        // Actualizar el estado localmente
        customer.openQuotation = newStatus
      } catch (error) {
        console.error('Error al actualizar el estado:', error)
        this.$toast.error('Hubo un problema al actualizar el estado.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.switch {
  margin-right: -18px;
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
}

/* Ocultar el checkbox predeterminado */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* El slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #04ff1d;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  transition: 0.4s;
  border: 1px solid #ffffff;
}

.slider.closed:before {
  border: 1px solid #04ff1d;
}

input:checked + .slider {
  background-color: #04ff1d;
}

input:focus + .slider {
  background-color: #04ff1d;
}

input:checked + .slider:before {
  transform: translateX(9px);
}

/* Redondear el slider */
.slider.round {
  border-radius: 34px;
  border: 1px solid #04ff1d;
}

.slider.round.closed {
  border: 1px solid #04ff1d;
}

.slider.round:before {
  border-radius: 50%;
}

.text-align-center {
  text-align: center;
}
</style>
